// i18n
import { i18n } from '@ecg-marktplaats/js-react-i18n';
import dateLocale from 'date-fns/locale/fr';
import common from '../../../../locales/fr-BE/common.json';

export default i18n.init({
  lng: 'fr-BE',
  translations: {
    common,
  },
  dateLocale,
});
